import header_logo from "../../../assets/images/uena_form_header.svg";
import humberger_button from "../../../assets/images/humberger_button.svg";

import Widget from "../../../commons/base/widget";
import UenaWebsiteState from "../../view_models/uena_website_state";
import UenaWebsiteViewModel from "../../view_models/uena_website_view_model";
import UenaWebsiteScreen from "../../uena_website_screen";
import { RefObject } from "react";

interface Props {
    homeRef: RefObject<HTMLDivElement>;
    ourMissionRef: RefObject<HTMLDivElement>;
    ourMenuRef: RefObject<HTMLDivElement>;
    ourQualityRef: RefObject<HTMLDivElement>;
    howToOrderRef: RefObject<HTMLDivElement>;
    scrollToRef: (ref: RefObject<HTMLDivElement>) => void;
}

export default class UenaWebsiteMobileHeaderWidget extends Widget<Props, UenaWebsiteState, UenaWebsiteViewModel, UenaWebsiteScreen> {
    homeRef: RefObject<HTMLDivElement>;
    ourMissionRef: RefObject<HTMLDivElement>;
    ourMenuRef: RefObject<HTMLDivElement>;
    ourQualityRef: RefObject<HTMLDivElement>;
    howToOrderRef: RefObject<HTMLDivElement>;

    get scrollToRef(): (ref: RefObject<HTMLDivElement>) => void {
        return this.props.scrollToRef;
    }

    constructor(props: Props & {vc: UenaWebsiteScreen}) {
        super(props);

        this.homeRef = props.homeRef;
        this.ourMissionRef = props.ourMissionRef;
        this.ourMenuRef = props.ourMenuRef;
        this.ourQualityRef = props.ourQualityRef;
        this.howToOrderRef = props.howToOrderRef;
    }

    render() {
        return <div id="HomeHeaderMobileContainer">
            <img src={header_logo} alt="" />
            <div />
            <button 
                onClick={() => this.viewModel.setShowModalMenuDrawer()}
            >
                <img src={humberger_button} alt="" />
            </button>
            <div style={{opacity: (Math.min(this.vmState.scrollPosition, 100) / 100) * 0.8}}/>
            {
                this.vmState.isShowingTopModalMenu &&
                <div id="HomeMobileModalMenuDrawer" onClick={() => this.viewModel.setShowModalMenuDrawer(false)}>
                    <div onClick={(event) => event.stopPropagation()}>
                        <button
                            onClick={() => {
                                this.scrollToRef(this.homeRef);
                                this.viewModel.setShowModalMenuDrawer(false);
                            }}
                        >HOME</button>
                        <button
                            onClick={() => {
                                this.scrollToRef(this.ourMissionRef);
                                this.viewModel.setShowModalMenuDrawer(false);
                            }}
                        >OUR MISSION</button>
                        <button
                            onClick={() => {
                                this.scrollToRef(this.ourMenuRef);
                                this.viewModel.setShowModalMenuDrawer(false);
                            }}
                        >OUR MENU</button>
                        <button
                            onClick={() => {
                                this.scrollToRef(this.ourQualityRef);
                                this.viewModel.setShowModalMenuDrawer(false);
                            }}
                        >OUR QUALITY</button>
                        <button
                            onClick={() => {
                                this.scrollToRef(this.howToOrderRef);
                                this.viewModel.setShowModalMenuDrawer(false);
                            }}
                        >HOW TO ORDER</button>
                    </div>
                </div>
            }
        </div>;
    }
}