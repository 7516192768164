import ApiService from "../../commons/api/api_service";
import BaseResponse from "../../commons/api/base_response";
import { Endpoints } from "../../commons/api/endpoints";
import ViewModel from "../../commons/base/view_model";
import { getLocationQueries } from "../../commons/utils/utils";
import NotificationWebDisplayRequest from "../models/notification_web_display_request";
import NotificationWebDisplayResponse from "../models/notification_web_display_response";
import NotificationWebDisplayState from "./notification_web_display_state";

export default class NotificationWebDisplayViewModel extends ViewModel<NotificationWebDisplayState> {
    constructor() {
        super(new NotificationWebDisplayState());
        this.loadContent();
    }

    loadContent() {
        const id = getLocationQueries()["id"];

        this.emit((state) => {
            state.isLoading = true;
            state.errorMessage = undefined;
        });

        ApiService.fetch<NotificationWebDisplayRequest, BaseResponse<NotificationWebDisplayResponse>>(
            Endpoints.notificationWebViewDisplay, {
            parameters: { id },
            onSuccess: (response) => {
                this.emit((state) => {
                    state.isLoading = false;

                    if (response.data) {
                        state.response = response.data;
                    } else {
                        state.errorMessage = response.message;
                    }
                });
            },
            onFailure: (error) => {
                this.emit((state) => {
                    state.isLoading = false;
                    state.errorMessage = `${error}`;
                });
            }
        }
        )
    }
}