import { isAndroid } from "react-device-detect";
import ApiService from "../../../commons/api/api_service";
import BaseResponse from "../../../commons/api/base_response";
import { Endpoints } from "../../../commons/api/endpoints";
import ViewModel from "../../../commons/base/view_model";
import { ShowSnackbarModel } from "../../../commons/scaffold/scaffold";
import ReferralDisplayRequest from "../models/referral_display_request";
import ReferralDisplayResponse from "../models/referral_display_response";
import ReferralState from "./referral_state";
import { getLocationQueries } from "../../../commons/utils/utils";

export default class ReferralViewModel extends ViewModel<ReferralState> {
    constructor() {
        super(new ReferralState());
        this.loadComponents();
    }

    loadComponents() {
        this.emit((state) => state.isLoading = true);

        const userId = getLocationQueries()["id"];

        this.emit((state) => {
            state.isLoading = true;
            state.errorViewModel = undefined;
        });

        ApiService.fetch<ReferralDisplayRequest, BaseResponse<ReferralDisplayResponse>>(Endpoints.referralDetail, {
            parameters: {
                userId: userId
            },
            onSuccess: (response) => {
                if (response.data) {
                    this.emit((state) => {
                        state.isLoading = false;
                        state.response = response.data;
                    });
                } else {
                    this.emit((state) => {
                        state.isLoading = false;
                        state.errorViewModel = {
                            message: response.message,
                            action: () => this.loadComponents()
                        }
                    });
                }
            },
            onFailure: (error) => {
                this.emit((state) => {
                    state.isLoading = false;
                    state.errorViewModel = {
                        message: `Terjadi kesalahan: ${JSON.stringify(error)}`,
                        action: () => this.loadComponents()
                    }
                });
            }
        });
    }

    changeActiveTabIndex(index: number) {
        this.emit((state) => state.activeTabIndex = index);
    }

    copyReferralCode() {
        const referralCode = this.state.response?.referralCode;

        if (referralCode) {
            if (isAndroid) {
                console.log(`copy_clipboard:${referralCode}`)
            } else {
                navigator.clipboard.writeText(referralCode);
            }
            this.emit((state) =>
                state.infoSnackbarModel = new ShowSnackbarModel({
                    message: "Kode Referral Berhasil disalin",
                    type: "normal"
                })
            )
        }
    }
}