import "./website.css";

import ViewController from "../commons/base/view_controller";
import UenaWebsiteState from "./view_models/uena_website_state";
import UenaWebsiteViewModel from "./view_models/uena_website_view_model";

export default class UenaDeleteAccountScreen extends ViewController<
    {},
    UenaWebsiteState,
    UenaWebsiteViewModel
> {
    constructor(props: {}) {
        super(props, new UenaWebsiteViewModel());
    }

    render() {
        return (
            <div id="DeleteAccountFormContainer">
                <h1>Delete Account UENA</h1>
                <p
                    className="FormLabel"
                    style={{
                        marginBottom: "12px",
                    }}
                >
                    Nomor Whatsapp (Diawali 62)
                </p>
                <div
                    style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "between",
                        gap: "12px",
                    }}
                >
                    <div
                        style={{
                            display: "flex",
                            flexDirection: "row",
                            gap: "12px",
                            width: "100%",
                        }}
                    >
                        <input
                            style={{
                                width: "100%",
                            }}
                            className="FormTextInput"
                            value={this.vmState.phoneNumber}
                            name="phoneNumber"
                            type="tel"
                            onChange={(event) =>
                                this.viewModel.changePhoneNumber(
                                    event.target.value
                                )
                            }
                            placeholder="Masukkan nomor whatsapp disini"
                        />
                        <button
                            style={{
                                backgroundColor: "gray",
                                borderRadius: 10,
                                color: "white",
                                padding: "6px 12px",
                                flexShrink: 0,
                                fontSize: 14,
                                fontWeight: "bold",
                                cursor: "pointer",
                            }}
                            disabled={
                                this.vmState.phoneNumber === "" &&
                                this.vmState.otp === ""
                            }
                            onClick={() => {
                                this.viewModel.getOtp();
                            }}
                        >
                            Kirim OTP
                        </button>
                    </div>
                    <input
                        className="FormTextInput"
                        value={this.vmState.otp}
                        name="phoneNumber"
                        type="tel"
                        onChange={(event) =>
                            this.viewModel.changeOtp(event.target.value)
                        }
                        placeholder="Masukkan Kode OTP"
                    />
                    <button
                        style={{
                            backgroundColor: "red",
                            borderRadius: 10,
                            color: "white",
                            padding: "6px 12px",
                            flexShrink: 0,
                            fontSize: 14,
                            fontWeight: "bold",
                            cursor: `${
                                this.vmState.phoneNumber === ""
                                    ? "not-allowed"
                                    : "pointer"
                            }`,
                        }}
                        disabled={
                            this.vmState.phoneNumber === "" &&
                            this.vmState.otp === ""
                        }
                        onClick={() => {
                            this.viewModel.deleteAccount();
                        }}
                    >
                        Delete Account
                    </button>
                </div>
            </div>
        );
    }
}
