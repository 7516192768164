import React from "react";
import StringUtils from "../../commons/utils/string_utils";
import OrderFormProductModel from "../models/order_form_product_model";

interface Props {
    model: OrderFormProductModel;
    refreshId: number;
    onProductQuantityOrNotesChanged: (id: string, quantity: number, model: OrderFormProductModel, isVoucherRequired: boolean) => void;
    productAndQuantity: Map<string, {
        quantity: number;
        model: OrderFormProductModel;
    }>;
    contentWidth: number;
}

export default class CreateOrderMenuCellWidget extends React.Component<Props> {
    private get quantity() {
        return this.props.productAndQuantity.get(this.props.model.id)?.quantity ?? 0;
    }
    
    render() {
        const parentWidth = this.props.contentWidth;
        let cellWidth: number;

        if (parentWidth < 380) {
            cellWidth = (parentWidth - 12) / 2;
        } else if (parentWidth < 660) {
            cellWidth = (parentWidth - 24) / 3;
        } else {
            cellWidth = (parentWidth - 48) / 5;
        }

        return <div id="CreateOrderMenuCellContainer" style={{
            width:`${cellWidth}px`,
        }}>
            <img src={this.props.model.imageUrl} alt="" />
            <p>{this.props.model.title}</p>
            <p>
                {
                    this.props.model.price > 0 ? 
                        StringUtils.getCurrencyStringFormat(
                            this.props.model.price
                        ) : 
                        "Gratis"
                }
            </p>
            <div>
                <button onClick={() => this.decreaseQuantity()}>-</button>
                <input 
                    key={`${this.props.refreshId}`}
                    value={this.quantity}
                    name="phoneNumber"
                    type="tel"
                    onChange={(event) => {
                        if (!isNaN(+event.target.value)) {
                            const quantity = +event.target.value;
                            this.props.onProductQuantityOrNotesChanged(this.props.model.id, quantity, this.props.model, this.props.model.isVoucherCodeRequired);
                        }
                    }}
                    placeholder="Masukkan nomor whatsapp disini"
                />
                <button onClick={() => this.increaseQuantity()}>+</button>
            </div>
            <textarea 
                key={`${this.props.refreshId}`}
                className="FormTextInput"
                style={{
                    flexGrow: 0
                }}
                value={this.props.model.notes}
                onChange={(event) => {
                    this.props.model.notes = event.target.value;
                    this.props.onProductQuantityOrNotesChanged(this.props.model.id, this.quantity, this.props.model, this.props.model.isVoucherCodeRequired);
                }}
                placeholder="Catatan Menu"
            />
        </div>;
    }

    private decreaseQuantity() {
        const newQuantity = this.quantity - 1;
        console.log(this.props.model)

        if (newQuantity >= 0) {
            this.props.onProductQuantityOrNotesChanged(this.props.model.id, this.quantity - 1, this.props.model, this.props.model.isVoucherCodeRequired);
        }
    }

    private increaseQuantity() {
        this.props.onProductQuantityOrNotesChanged(this.props.model.id, this.quantity + 1, this.props.model, this.props.model.isVoucherCodeRequired);
    }
}