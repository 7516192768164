import Widget from "../../../commons/base/widget";
import ReferralScreen from "../referral_screen";
import ReferralState from "../view_models/referral_state";
import ReferralViewModel from "../view_models/referral_view_model";
import ReferralReferredItemWidget from "./referral_referred_item_widget";
import ReferralReferredLoadingItemWidget from "./referral_referred_loading_item_widget";
import ReferralReferredNoItemWidget from "./referral_referred_no_item_widget";

export default class ReferralReferredSectionWidget extends Widget<{}, ReferralState, ReferralViewModel, ReferralScreen> {
    render() {
        return <div id="ReferralReferredContainer">
            {
                this.vmState.isLoading
                    ? <>
                        {
                            [0, 1, 2, 3, 4, 5].map((value) => <ReferralReferredLoadingItemWidget key={value} />)
                        }
                    </>
                    : <>
                        {
                            this.vmState.invitedUsersCounting > 0 
                                ? <>
                                    {
                                        this.vmState.response?.invitedUsers.map(
                                            (model) => <ReferralReferredItemWidget key={`${model.name}_${model.points}`} name={model.name} points={model.points} />
                                        )
                                    }
                                </> 
                                : <ReferralReferredNoItemWidget />
                        }
                    </>
            }
        </div>;
    }
}