import React from "react";
import { getScreenSizeWithMaxWidth } from "../../../commons/utils/utils";

interface Props {
    index: number;
    image: string;
    detail: string;
}

export default class ReferralStepItemWidget extends React.Component<Props> {
    render() {
        const cellWidth = getScreenSizeWithMaxWidth().width / 2.0 - 30.5;

        return <div className="ReferralItemContainer" style={{width: cellWidth}}>
            <div>{this.props.index}</div>
            <img src={this.props.image} alt="" style={{
                height: cellWidth - 24.0,
                width: cellWidth - 24.0
            }} />
            <p>{this.props.detail}</p>
        </div>; 
    }
}