import React from "react";
import { getScreenSizeWithMaxWidth } from "../../../commons/utils/utils";
import { Skeleton } from "@mui/material";

export default class ReferralStepLoadingItemWidget extends React.Component {
    render() {
        const width = getScreenSizeWithMaxWidth().width / 2.0 - 31.5;

        return <div className="ReferralLoadingItemContainer" style={{width}}>
            <div style={{
                width: width - 32.0,
                height: width - 32.0
            }}>
                <Skeleton variant="circular" width="75%" height="75%" />
            </div>
            <Skeleton variant="rectangular" width="100%" height={24} />
        </div>; 
    }
}