import { ShowErrorViewModel, ShowSnackbarModel } from "../../../commons/scaffold/scaffold";
import MobileQrisResponse from "../models/mobile_qris_response";

export default class MobileQrisState {
    isLoading: boolean = true;
    response?: MobileQrisResponse;
    errorViewModel?: ShowErrorViewModel;
    timeDifferenceInSecond: number = 0;
    isCheckingQrisStatus: boolean = false;
    errorSnackbar?: ShowSnackbarModel;
}