import Widget from "../../../commons/base/widget";
import ReferralScreen from "../referral_screen";
import ReferralState from "../view_models/referral_state";
import ReferralStepItemWidget from "./referral_step_item_widget";
import ReferralViewModel from "../view_models/referral_view_model";
import ReferralStepLoadingItemWidget from "./referral_step_loading_item_widget";

export default class ReferralStepSectionWidget extends Widget<{}, ReferralState, ReferralViewModel, ReferralScreen> {
    render() {
        return <div id="ReferralStepsContainer">
            {
                this.vmState.response
                ? this.vmState.response?.steps.map(
                    (model, index) => <ReferralStepItemWidget 
                        key={index}
                        index={index + 1}
                        image={model.imageUrl}
                        detail={model.detail}
                    />
                ) 
                : [0, 1, 2, 3].map((value) => <ReferralStepLoadingItemWidget key={value} />)
            }
        </div>;
    }
}