import ApiService from "../../commons/api/api_service";
import { Endpoints } from "../../commons/api/endpoints";
import ViewModel from "../../commons/base/view_model";
import DeleteAccountRequest from "../models/requests/delete_account_request";
import OtpRequest from "../models/requests/otp_request";
import DeleteAccountResponse from "../models/responses/delete_account_response";
import OtpResponse from "../models/responses/otp_response";
import UenaWebsiteState, { UenaWebsiteActiveMenu } from "./uena_website_state";

const HOME_POSITION_FROM_SCREEN_POSITION = 1;
const MISSION_POSITION_FROM_SCREEN_POSITION = 2;
const OUR_MENU_POSITION_FROM_SCREEN_POSITION = 3.5;
const OUR_QUALITY_POSITION_FROM_SCREEN_POSITION = 4.5;
const WHY_FOODCOURT_POSITION_FROM_SCREEN_POSITION = 5.5;
const HOW_TO_ORDER_POSITION_FROM_SCREEN_POSITION = 6.5;

export default class UenaWebsiteViewModel extends ViewModel<UenaWebsiteState> {
    constructor() {
        super(new UenaWebsiteState());
    }

    updateScrollPosition(screenHeight: number, isDesktopViewSize: boolean) {
        const scrollPositionFromTop =
            document.body.scrollTop || document.documentElement.scrollTop;
        const maxTopScrollPosition = document.body.scrollHeight - screenHeight;
        const footerHeight = isDesktopViewSize ? 157 : 194;

        this.emit((state) => {
            state.scrollPosition = scrollPositionFromTop;

            if (
                scrollPositionFromTop <
                HOME_POSITION_FROM_SCREEN_POSITION * screenHeight
            ) {
                state.activeMenu = UenaWebsiteActiveMenu.home;
            } else if (
                scrollPositionFromTop <
                MISSION_POSITION_FROM_SCREEN_POSITION * screenHeight - 200
            ) {
                state.activeMenu = UenaWebsiteActiveMenu.ourMission;
            } else if (
                scrollPositionFromTop <
                OUR_MENU_POSITION_FROM_SCREEN_POSITION * screenHeight - 200
            ) {
                state.activeMenu = UenaWebsiteActiveMenu.ourMenu;
            } else if (
                scrollPositionFromTop <
                OUR_QUALITY_POSITION_FROM_SCREEN_POSITION * screenHeight - 200
            ) {
                state.activeMenu = UenaWebsiteActiveMenu.ourQuality;
            } else if (
                scrollPositionFromTop <
                WHY_FOODCOURT_POSITION_FROM_SCREEN_POSITION * screenHeight - 200
            ) {
                state.activeMenu = UenaWebsiteActiveMenu.ourQuality;
            } else if (
                scrollPositionFromTop <
                HOW_TO_ORDER_POSITION_FROM_SCREEN_POSITION * screenHeight - 200
            ) {
                state.activeMenu = UenaWebsiteActiveMenu.howToOrder;
            }

            if (
                scrollPositionFromTop <
                maxTopScrollPosition - (footerHeight - 24)
            ) {
                state.orderNowBottomInset = 24;
            } else {
                state.orderNowBottomInset =
                    footerHeight -
                    (maxTopScrollPosition - scrollPositionFromTop);
            }

            if (isDesktopViewSize) {
                this.setShowModalMenuDrawer(false);
            }
        });
    }

    setShowModalMenuDrawer(value?: boolean) {
        this.emit((state) => {
            state.isShowingTopModalMenu = value ?? !state.isShowingTopModalMenu;
        });
    }

    changePhoneNumber(value: string) {
        this.emit((state) => {
            state.phoneNumber = value;
        });
    }

    changeOtp(value: string) {
        this.emit((state) => {
            state.otp = value;
        });
    }

    getOtp() {
        if (this.state.phoneNumber.length === 0) {
            alert("Please input phone number");
            return;
        }
        ApiService.fetch<OtpRequest, OtpResponse>(Endpoints.getOTP, {
            parameters: {
                phone_number: this.state.phoneNumber,
            },
            onSuccess: (response) => {
                alert(response.message);
                // this.emit((state) => {});
            },
            onFailure: (failure) => {
                alert(failure.message ?? "Failed to get OTP");
                this.emit((state) => {});
            },
        });
    }

    deleteAccount() {
        if (this.state.phoneNumber.length === 0) {
            alert("Please input phone number");
            return;
        }
        ApiService.fetch<DeleteAccountRequest, DeleteAccountResponse>(
            Endpoints.deleteAccount,
            {
                parameters: {
                    phone_number: this.state.phoneNumber,
                    otp: this.state.otp,
                },
                onSuccess: (response) => {
                    if (response.message === "OK") {
                        alert("Berhasil hapus akun");
                    } else {
                        alert(response.message);
                    }
                },
                onFailure: (failure) => {
                    alert(failure.message ?? "Failed to get OTP");
                    this.emit((state) => {});
                },
            }
        );
    }
}
