import "./home.css";

import React from "react";
import Scaffold from "../commons/scaffold/scaffold";
import { HomeFeatureButton } from "./widgets/home_feature_button";

export default class CrmHomeScreen extends React.Component {
    render() {
        return <Scaffold>
            <div id="HomeScreenContainer">
                <HomeFeatureButton title="Adhoc CRM" path="/adhoc-crm"/>
                <HomeFeatureButton title="Notification Web" path="/notification-web-form"/>
                <HomeFeatureButton title="Web Redirection" path="/redirect-form"/>
            </div>
        </Scaffold>;
    }
}

