import uena_coin from "../../../assets/images/uena-coin.svg";

import React from "react";
import { Skeleton } from "@mui/material";

export default class ReferralReferredLoadingItemWidget extends React.Component {
    render() {
        return <div className="ReferralReferredLoadingItem">
            <div>
                <Skeleton variant="rectangular" height={21.5} width="45%" />
            </div>
            <img src={uena_coin} alt="" />
            <Skeleton variant="rectangular" height={21.5} width="32%" />
        </div>
    }
}