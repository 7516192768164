import uena_form_header from "../../assets/images/uena_form_header.svg";

import "./scaffold.css";

import React, { ReactNode } from "react";
import LoadingStateView, { LoadingBackgroundType, LoadingType } from "../ui/loading/loading_state_view";
import ErrorStateView from "../ui/error/error_state_view";

export class ShowSnackbarModel {
    type?: "error" | "info" | "normal";
    message: string;
    key: string;
    inset?: {
      bottom?: number;
    }

    constructor(args: { message: string; type?: "error" | "info" | "normal", inset?: {
      bottom?: number;
    } }) {
        this.type = args.type;
        this.message = args.message;
        this.key = Date().toLocaleString();
        this.inset = args.inset
    }
}

export interface ShowErrorViewModel {
    message: string;
    action: () => void;
}

export interface ShowPopUpModel {
    child: JSX.Element;
}

interface Props {
    children?: ReactNode;
    isShowLoading?: boolean;
    loadingBackgroundType?: LoadingBackgroundType;
    loadingType?: LoadingType;
    loadingBackgroundColor?: string
    showSnackbar?: ShowSnackbarModel | null;
    showTopAppBar?: boolean;
    showErrorView?: ShowErrorViewModel;
    onlyForAdmin?: boolean;
    disableFullHeight?: boolean;
    showPopUp?: ShowPopUpModel;
}

interface State {
    showSnackbar?: ShowSnackbarModel;
}

export default class Scaffold extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {};
        this.setSafeAreaHeight();
        window.addEventListener('resize', () => {
            this.setSafeAreaHeight();
        });
    }

    render() {
        const snackbarModel = this.props.showSnackbar ?? this.state.showSnackbar;

        if (snackbarModel) {
            setTimeout(() => {
                this.setState({showSnackbar: undefined});
            }, 4500);
        }

        return <div id="Scaffold" style={{
            height: this.props.disableFullHeight === false ? undefined : "calc(var(--vh, 1vh) * 100)"
        }}>
            {
                (this.props.showTopAppBar ?? true) &&
                <a id="ScaffoldHeader" href="/">
                    <img src={uena_form_header} alt="" />
                </a>
            }
            {
                this.props.children
            }
            {
                this.props.isShowLoading && <LoadingStateView backgroundType={this.props.loadingBackgroundType} loadingType={this.props.loadingType} backgroundColor={this.props.loadingBackgroundColor} />
            }
            {
                this.props.showErrorView && <ErrorStateView message={this.props.showErrorView.message} action={this.props.showErrorView.action}/>
            }
            {
                snackbarModel &&
                    <div
                        id="SnackbarContainer"
                        key={snackbarModel.key}
                        style={{
                            maxWidth: 500,
                            width: '95%',
                            zIndex: 999,
                            position: 'fixed',
                            left: 'calc(50%)',
                            transform: 'translateX(-50%)'
                        }}
                    >
                        <div id="SnackbarContainer" style={{
                                backgroundColor: snackbarModel.type === "info" ?  "#5797FF" : snackbarModel.type === 'normal' ? '#1FB81A' : "#F14100",
                        }}>
                            {snackbarModel.message}
                        </div>
                    </div>
            }
            {
                this.props.showPopUp &&
                <div id="PopUpBackground">
                    <div id="PopUpContainer">
                        {
                            this.props.showPopUp.child
                        }
                    </div>
                </div>
            }
        </div>;
    }

    private setSafeAreaHeight() {
        let vh = window.innerHeight * 0.01;
        document.documentElement.style.setProperty('--vh', `${vh}px`);
    }
}