import quality_1 from "../../../assets/images/quality_1.avif";
import quality_2 from "../../../assets/images/quality_2.avif";
import quality_3 from "../../../assets/images/quality_3.avif";
import quality_4 from "../../../assets/images/quality_4.avif";

import Widget from "../../../commons/base/widget";
import UenaWebsiteState from "../../view_models/uena_website_state";
import UenaWebsiteViewModel from "../../view_models/uena_website_view_model";
import UenaWebsiteScreen from "../../uena_website_screen";
import { RefObject } from "react";

interface Props {
    ourQualityRef: RefObject<HTMLDivElement>;
}

export default class UenaWebsiteMobileQualityWidget extends Widget<Props, UenaWebsiteState, UenaWebsiteViewModel, UenaWebsiteScreen> {
    render() {
        return <div ref={this.props.ourQualityRef}>
            <div id="HomeQualityMobileContainer">
                <h2>How is the <strong>Quality?</strong></h2>
                <div>
                    <img src={quality_1} alt="" />
                    <img src={quality_2} alt="" />
                    <img src={quality_3} alt="" />
                    <img src={quality_4} alt="" />
                </div>
            </div>
        </div>;
    }
}