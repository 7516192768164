import ApiService from "../../../commons/api/api_service";
import BaseResponse from "../../../commons/api/base_response";
import { Endpoints } from "../../../commons/api/endpoints";
import ViewModel from "../../../commons/base/view_model";
import { ShowSnackbarModel } from "../../../commons/scaffold/scaffold";
import { getLocationQueries } from "../../../commons/utils/utils";
import MobileQrisCheckOrderRequest from "../models/mobile_qris_check_order_request";
import MobileQrisCheckOrderResponse from "../models/mobile_qris_check_order_response";
import MobileQrisCheckStatusRequest from "../models/mobile_qris_check_status_request";
import MobileQrisCheckStatusResponse from "../models/mobile_qris_check_status_response";
import MobileQrisRequest from "../models/mobile_qris_request";
import MobileQrisResponse from "../models/mobile_qris_response";
import MobileQrisState from "./mobile_qris_state";

export default class MobileQrisViewModel extends ViewModel<MobileQrisState> {
    constructor() {
        super(new MobileQrisState());
        this.loadContent();
    }

    handleCheckStatusIsPaid() {
        const refCode = getLocationQueries()["ref_code"];

        this.emit((state) => {
            state.isCheckingQrisStatus = true;
            state.errorSnackbar = undefined;
        });

        setTimeout(() => {
            ApiService.fetch<MobileQrisCheckStatusRequest, BaseResponse<MobileQrisCheckStatusResponse>>(Endpoints.mobileQrisCheckStatus, {
                parameters: {
                    ref_id: refCode
                },
                onSuccess: (response) => {
                    if (response.data) {
                        if (response.data.status === "PAID") {
                            this.handleCheckOrderStatus();
                        } else {
                            this.emit((state) => {
                                state.isCheckingQrisStatus = false;
                                state.errorSnackbar = new ShowSnackbarModel({
                                    message: `Status pembayaran saat ini ${response.data?.status}. Jika anda sudah membayar, kemungkinan sistem masih dalam proses validasi. Cobalah beberapa saat lagi.`,
                                    type: "error"
                                });
                            });
                        }
                    } else {
                        this.emit((state) => {
                            state.isCheckingQrisStatus = false;
                            state.errorSnackbar = new ShowSnackbarModel({
                                message: response.message,
                                type: "error"
                            });
                        });
                    }
                },
                onFailure: (error) => {
                    this.emit((state) => {
                        state.errorSnackbar = new ShowSnackbarModel({
                            message: `Terjadi error: ${error}`,
                            type: "error"
                        });
                    });
                }
            });
        }, 2000);
    }

    private loadContent() {
        this.emit((state) => {
            state.isLoading = true;
            state.errorViewModel = undefined;
        });

        ApiService.fetch<MobileQrisRequest, BaseResponse<MobileQrisResponse>>(Endpoints.mobileQrisDisplay, {
            parameters: {
                ref_id: getLocationQueries()["ref_code"]
            },
            onSuccess: (response) => {
                this.emit((state) => {
                    state.isLoading = false;

                    if (response.data) {
                        state.response = response.data;
                        state.timeDifferenceInSecond = (new Date(state.response.expiresAt).getTime() - new Date().getTime()) / 1000;

                        this.runTimer();
                    } else {
                        state.errorViewModel = {
                            message: response.message,
                            action: () => this.loadContent()
                        }
                    }
                });
            },
            onFailure: (error) => {
                this.emit((state) => {
                    state.isLoading = false;
                    state.errorViewModel = {
                        message: error,
                        action: () => this.loadContent()
                    }
                });
            }
        });
    }

    private runTimer() {
        this.emit((state) => state.timeDifferenceInSecond -= 1);

        if (this.state.timeDifferenceInSecond > 0) {
            setTimeout(() => this.runTimer(), 1000);
        }
    }

    private handleCheckOrderStatus() {
        const refCode = getLocationQueries()["ref_code"];

        ApiService.fetch<MobileQrisCheckOrderRequest, BaseResponse<MobileQrisCheckOrderResponse>>(Endpoints.mobileQrisCheckOrder, {
            parameters: {
                ref_id: refCode
            },
            onSuccess: (response) => {
                if (response.data?.status === "PAID_COOKING") {
                    window.location.href = `https://uenafood.com/order/${getLocationQueries()["ref_code"]}`;
                } else {
                    setTimeout(() => this.handleCheckOrderStatus(), 1500);
                }
            },
            onFailure: (error) => {
                this.emit((state) => {
                    state.isCheckingQrisStatus = false;
                    state.errorSnackbar = new ShowSnackbarModel({
                        message: `Terjadi error: ${error}`,
                        type: "error"
                    });
                });
            }
        });
    }
}