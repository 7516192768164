import { initializeApp } from '@firebase/app';
import { getAnalytics } from "@firebase/analytics";

const firebaseConfig = process.env.REACT_APP_ENV === "production" ?
    {
        apiKey: "AIzaSyAGFMi1xgDYoxu13T2cqJ4o64z5mTIhxz4",
        authDomain: "uena-web.firebaseapp.com",
        projectId: "uena-web",
        storageBucket: "uena-web.appspot.com",
        messagingSenderId: "828537965053",
        appId: "1:828537965053:web:d81fe2e69cad6ec8c67194",
        measurementId: "G-WBVSYBZR5D"
    } :
    {
        apiKey: "AIzaSyBNZz3c-CZjZowHPrmbeub46McJdlzgFaI",
        authDomain: "uena-web-staging.firebaseapp.com",
        projectId: "uena-web-staging",
        storageBucket: "uena-web-staging.appspot.com",
        messagingSenderId: "506265406499",
        appId: "1:506265406499:web:dbfd4a3a287fcb726a7596",
        measurementId: "G-RX7X01DEQF"
    };


const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
analytics.app.automaticDataCollectionEnabled = true;

export default analytics;