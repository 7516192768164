import React from "react";
import { SoFormItem } from "../models/SoFormModels";

export default class TableSOItems extends React.Component<{
    formItems: SoFormItem[]
}> {

    renderFormItems() {
        const formItems = this.props.formItems ?? [];
        let rowCount = 1;
        if (formItems.length > 0) {
            return formItems.map((item) => {
                const bigQty = item.bigQuantity
                const smallQty = item.smallQuantity
                if ((bigQty + smallQty) > 0) {
                    const tableRow = (
                        <>
                            <tr key={rowCount}>
                                <td>{rowCount}</td>
                                <td>{item.itemName ?? "-"}</td>
                                <td>{item.bigQuantity ?? "0"}</td>
                                <td>{item.bigUnit ?? "-"}</td>
                                <td>{item.smallQuantity ?? "0"}</td>
                                <td>{item.smallUnit ?? "-"}</td>
                            </tr>
                        </>
                    );
                    rowCount++;
                    return tableRow;
                } else {
                    return null;
                }
            });
        } else {
            return (
                <tr>
                    <td colSpan={6}>
                        <span>Data Tidak Tersedia</span>
                    </td>
                </tr>
            );
        }
    }

    render(): React.ReactNode {
        return (
            <>
                <table className="SoTable">
                    <thead>
                        <tr>
                            <td>No.</td>
                            <td>Item Name</td>
                            <td>Big Qty</td>
                            <td>Unit</td>
                            <td>Small Qty</td>
                            <td>Unit</td>
                        </tr>
                    </thead>
                    <tbody>
                        { this.renderFormItems() }
                    </tbody>
                </table>
            </>
        );
    }
}
