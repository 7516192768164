import "./web_redirect_display.css"

import WebRedirectDisplayState from "../view_models/web_redirect_display_state";
import WebRedirectDisplayViewModel from "../view_models/web_redirect_display_view_model";
import ViewController from "../../commons/base/view_controller";
import UenaLogoLoadingIndicatorWidget from "../../commons/ui/loading/uena_logo_loading_indicator_widget";
import FormButton from "../../commons/ui/form_button";

export default class WebRedirectDisplayScreen extends ViewController<{}, WebRedirectDisplayState, WebRedirectDisplayViewModel> {
    constructor(props: {}) {
        super(props, new WebRedirectDisplayViewModel());
    }

    render() {
        if (this.vmState.errorMessage) {
            return <div id="NotificationWebDisplayErrorContainer">
                <div>!</div>
                <p>{this.vmState.errorMessage}</p>
                <FormButton 
                    backgroundColor="#6B2C8F"
                    textColor="white"
                    title="Coba Muat Konten Kembali"
                    action={() => this.viewModel.loadContent()}
                />
            </div>;
        }
        
        return <div id="WebRedirectionDisplayContainer">
            <UenaLogoLoadingIndicatorWidget />
        </div>;
    }
}