import uena_coin from "../../../assets/images/uena-coin.svg";

import React from "react";
import StringUtils from "../../../commons/utils/string_utils";

interface Props {
    name: string;
    points: number;
}

export default class ReferralReferredItemWidget extends React.Component<Props> {
    render() {
        return <div className="ReferralReferredItem">
            <p>{this.props.name}</p>
            <img src={uena_coin} alt="" />
            <p>+ {StringUtils.convertToCurrency(this.props.points, "")}</p>
        </div>
    }
}