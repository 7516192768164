import no_referral_users from "../../../assets/images/no-referral-users.svg";

import React from "react";

export default class ReferralReferredNoItemWidget extends React.Component {
    render() {
        return <div id="ReferralReferredNoItem">
            <img src={no_referral_users} alt="" />
            <p>Yuk undang teman kamu sebanyak-banyaknya</p>
        </div>;
    }
}