import './App.css';
import analytics from './commons/utils/analytics_utils';
import { getLocationQueries } from './commons/utils/utils';
import { UenaRouter } from './router/uena_router';
import {logEvent} from "@firebase/analytics";

function App() {
  logEvent(analytics, 'screen_view', {
    firebase_screen: window.location.pathname,
    firebase_screen_class: window.location.pathname,
    ...getLocationQueries()
  }, { global: true });

  return <UenaRouter />;
}

export default App;
