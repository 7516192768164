import "./error_state.css";

import React from "react";
import FormButton from "../form_button";

interface Props {
    message: string;
    action: () => void
}

export default class ErrorStateView extends React.Component<Props> {
    render() {
        console.log(this.props)
        return <div id="ErrorStateContainer">
            <div>!</div>
            <p>{`${this.props.message}`}</p>
            <FormButton 
                backgroundColor="#6B2C8F"
                textColor="white"
                title="Coba Lagi"
                action={() => this.props.action()}
            />
        </div>;
    }
}