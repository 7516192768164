import React from "react";

interface Props {
    message: string;
    isShowing?: boolean;
}

export default class FormErrorMessage extends React.Component<Props> {
    render() {
        return <>
            {   
                (this.props.isShowing ?? true) &&
                <p style={{
                    fontSize: 12,
                    color: "#F9453C",
                    fontWeight: "bold"
                }}>
                    {this.props.message}
                </p>
            }
        </>;
    }
}