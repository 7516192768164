export default class StringUtils {
    static getCurrencyStringFormat(amount: number): string {
        return Intl.NumberFormat("id-ID", {
            style: "currency",
            currency: "IDR"
        }).format(amount);
    }

    static checkIsValidUrl(value: string): boolean {
        return !!new RegExp('^(https?:\\/\\/)?' + // validate protocol
            '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // validate domain name
            '((\\d{1,3}\\.){3}\\d{1,3}))' + // validate OR ip (v4) address
            '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // validate port and path
            '(\\?[;&a-z\\d%_.~+=-]*)?' + // validate query string
            '(\\#[-a-z\\d_]*)?$', 'i').test(value);
    }

    static convertToCurrency(val?: number | null, currencyLabel: string = "Rp "): string {
        let currencyNumberFormat = Intl.NumberFormat('en-US');
        let priceLabel = currencyNumberFormat.format(val ?? 0);

        return `${currencyLabel}${priceLabel}`
    }
}