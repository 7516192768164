import "./referral.css";

import share from "../../assets/images/share.svg";

import referral_top_background from "../../assets/images/referral-background.svg";

import ViewController from "../../commons/base/view_controller";
import Scaffold from "../../commons/scaffold/scaffold";
import ReferralViewModel from "./view_models/referral_view_model";
import ReferralState from "./view_models/referral_state";
import ReferralTabButtonWidget from "./widgets/referral_tab_button_widget";
import ReferralStepSectionWidget from "./widgets/referral_step_section_widget";
import ReferralReferredSectionWidget from "./widgets/referral_referred_section_widget";
import ReferralCodeContainerWidget from "./widgets/referral_code_container_widget";
import { isAndroid } from "react-device-detect";

export default class ReferralScreen extends ViewController<{}, ReferralState, ReferralViewModel> {
    constructor(props: {}) {
        super(props, new ReferralViewModel());
    }

    render() {
        const imageTopBackgroundHeight = 0.6 * this.state.width;

        return <Scaffold
            showSnackbar={this.vmState.infoSnackbarModel}
            showErrorView={this.vmState.errorViewModel}
            showTopAppBar={false}
            disableFullHeight={false}
            loadingType="uena_logo"
            loadingBackgroundColor="#6B2C8F"
        >
            <div id="ReferralDisplayContainer">
                <img 
                    src={referral_top_background} 
                    alt="" 
                    style={{ height: imageTopBackgroundHeight }}
                />
                <ReferralCodeContainerWidget 
                    imageTopBackgroundHeight={imageTopBackgroundHeight}
                    vc={this}
                />
                <div id="ReferralTabContainer">
                    <div>
                        <ReferralTabButtonWidget 
                            label="Langkah - Langkah"
                            isActive={this.vmState.activeTabIndex === 0} 
                            onTapped={() => this.viewModel.changeActiveTabIndex(0)}
                        />
                        <ReferralTabButtonWidget 
                            label={`Yang Diundang ${this.vmState.invitedUsersCounting > 0 ? `(${this.vmState.invitedUsersCounting})` : ""}`}
                            isActive={this.vmState.activeTabIndex === 1} 
                            onTapped={() => this.viewModel.changeActiveTabIndex(1)}
                        />
                    </div>
                    {
                        this.vmState.activeTabIndex === 0 
                            ? <ReferralStepSectionWidget vc={this} /> 
                            : <ReferralReferredSectionWidget vc={this}/>
                    }
                </div>
                    <button id="ReferralShareButton" onClick={() => {
                        const title = "UENA Pujasera Online";
                        const shareText = `Sudah tau tentang aplikasi *UENA* belum? Aplikasi yang cocok jadi teman makan setiap harimu dan ga bikin dompetmu boncos. Yuk download sekarang dan gunakan kode referral *${this.vmState.response?.referralCode}*, kamu langsung dapat 3000 UENA Points loh!\n\nAYO DOWNLOAD SEKARANG!!!`;
                        const link = "https://uenafood.com/download";
                        
                        if (isAndroid) {
                            console.log(`share_content::${title};;${shareText};;${link}`)
                        } else {
                            navigator.share({
                                title: title,
                                text: shareText,
                                url: link
                            })
                        }
                    }}>
                        <img src={share} alt="" />
                    </button>
            </div>
        </Scaffold>;
    }
}