import React from "react";

interface Props {
    height?: number;
    width?: number;
}

export default class SizedDiv extends React.Component<Props, {}> {
    render() {
        return <div className="SizedDiv" style={{
            width: this.props.width, 
            height: this.props.height
        }}/>;
    }
}