import React from "react";

interface Props {
    title: string;
    path: string;
}

export class HomeFeatureButton extends React.Component<Props> {
    render() {
        return <button className="HomeFeatureButtonContainer" onClick={() => window.location.href = this.props.path}>
            <p>{this.props.title}</p>
        </button>
    }
}