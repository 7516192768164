interface HomeMenuListModel {
    name: string;
    price: number;
    imageUrl: string;
    isComingSoon: boolean;
    isNew?: boolean;
}

const LIST_OF_MENU: HomeMenuListModel[] = [
    {
        name: "Bistik Ayam Geprek Sambal Arang",
        imageUrl: "https://kokilogy-storage.s3.ap-southeast-1.amazonaws.com/menus/August2023/MJBueRUMU71NKpBTR1HcLro1iekF7Yko0nu2HLn5.png",
        isComingSoon: false,
        price: 20900,
        isNew: true
    },
    {
        name: "Ayam Geprek Sambal Korek",
        imageUrl: "https://kokilogy-storage.s3.ap-southeast-1.amazonaws.com/website/menu/ayam_geprek_korek.png",
        isComingSoon: false,
        price: 16500,
        isNew: false
    },
    {
        name: "Ayam Geprek Sambal Matah",
        imageUrl: "https://kokilogy-storage.s3.ap-southeast-1.amazonaws.com/website/menu/ayam_geprek_matah.png",
        isComingSoon: false,
        price: 16500,
        isNew: false
    },
    {
        name: "Nasi Goreng",
        imageUrl: "https://kokilogy-storage.s3.ap-southeast-1.amazonaws.com/website/menu/nasi_goreng.avif",
        isComingSoon: false,
        price: 16500
    },
    {
        name: "Ayam Goreng Kremes",
        imageUrl: "https://kokilogy-storage.s3.ap-southeast-1.amazonaws.com/website/menu/ayam_goreng.avif",
        isComingSoon: false,
        price: 16500
    },
    {
        name: "Soto Lamongan",
        imageUrl: "https://kokilogy-storage.s3.ap-southeast-1.amazonaws.com/website/menu/soto_lamongan.png",
        isComingSoon: false,
        price: 16500
    },
    {
        name: "Soto Betawi",
        imageUrl: "https://kokilogy-storage.s3.ap-southeast-1.amazonaws.com/website/menu/soto_betawi.avif",
        isComingSoon: false,
        price: 16500
    },
    {
        name: "Mie Ayam",
        imageUrl: "https://kokilogy-storage.s3.ap-southeast-1.amazonaws.com/website/menu/mie_ayam.png",
        isComingSoon: false,
        price: 16500
    }
];

export default LIST_OF_MENU;