import ViewController from "../commons/base/view_controller";
import UenaLogoLoadingIndicatorWidget from "../commons/ui/loading/uena_logo_loading_indicator_widget";
import DownloadAppState from "./view_models/download_app_state";
import DownloadAppViewModel from "./view_models/download_app_view_model";

export default class DownloadAppScreen extends ViewController<{}, DownloadAppState, DownloadAppViewModel> {
    constructor(props: {}) {
        super(props, new DownloadAppViewModel());
    }

    render() {
        return <div id="WebRedirectionDisplayContainer">
            <UenaLogoLoadingIndicatorWidget />
        </div>;
    }
}