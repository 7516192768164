import React from "react";
import { getScreenSizeWithMaxWidth } from "../utils/utils";
import ViewModel from "./view_model";

interface ViewControllerState<State, VM extends ViewModel<State>> {
    viewModel: VM;
    width: number;
    height: number;
}

export default class ViewController<Props, State, VM extends ViewModel<State>> extends React.Component<Props, ViewControllerState<State, VM>> {
    get viewModel(): VM {
        return this.state.viewModel;
    }

    get vmState(): State {
        return this.state.viewModel.state;
    }

    constructor(props: Props, viewModel: VM) {
        super(props);

        const screenSize = getScreenSizeWithMaxWidth();

        this.state = {
            viewModel: viewModel,
            width: screenSize.width,
            height: screenSize.height
        };

        window.addEventListener("resize", () => this.onScreenResizing());

        this.viewModel.emit = (action: (state: State) => void) => {
            action(this.viewModel.state);

            this.setState({
                viewModel: this.state.viewModel
            });
        };
    }

    onScreenResizing() {
        this.updateSize();
    }

    private updateSize() {
        const screenSize = getScreenSizeWithMaxWidth();
        
        this.setState({
            viewModel: this.state.viewModel,
            width: screenSize.width,
            height: screenSize.height
        });
    }
}