import ApiService from "../../commons/api/api_service";
import BaseResponse from "../../commons/api/base_response";
import { Endpoints } from "../../commons/api/endpoints";
import ViewModel from "../../commons/base/view_model";
import { getLocationQueries } from "../../commons/utils/utils";
import WebRedirectDisplayRequest from "../models/web_redirect_display_request";
import WebRedirectDisplayResponse from "../models/web_redirect_display_response";
import WebRedirectDisplayState from "./web_redirect_display_state";

export default class WebRedirectDisplayViewModel extends ViewModel<WebRedirectDisplayState> {
    constructor() {
        super(new WebRedirectDisplayState());
        setTimeout(() => this.loadContent(), 4000);

    }

    loadContent() {
        const id = getLocationQueries()["id"];

        this.emit((state) => {
            state.isLoading = true;
            state.errorMessage = undefined;
        });

        ApiService.fetch<WebRedirectDisplayRequest, BaseResponse<WebRedirectDisplayResponse>>(
            Endpoints.redirectWebGet, {
            parameters: { id },
            onSuccess: (response) => {
                this.emit((state) => {
                    state.isLoading = false;

                    if (response.data) {
                        window.location.href = response.data.redirectUrl;
                    } else {
                        state.errorMessage = response.message;
                    }
                });
            },
            onFailure: (error) => {
                this.emit((state) => {
                    state.isLoading = false;
                    state.errorMessage = `${error}`;
                });
            }
        }
        )
    }
}