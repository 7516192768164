interface SignInInfoModel {
    driverId: string;
    signInTime: string;
}

export class DriverUtils {
    private static DRIVER_SIGN_IN_INFO = "DRIVER_SIGN_IN_INFO";

    static getSignInValues(): SignInInfoModel | undefined {
        const json = localStorage.getItem(this.DRIVER_SIGN_IN_INFO);

        if (json) {
            return JSON.parse(json);
        }
    }

    static setSignInValues(values: SignInInfoModel) {
        localStorage.setItem(this.DRIVER_SIGN_IN_INFO, JSON.stringify(values));
    }

    static logOut() {
        localStorage.removeItem(this.DRIVER_SIGN_IN_INFO);
        window.location.href = "/sign_in";
    }
}