import radio_active from "../../../assets/images/radio_active.svg";
import radio_unactive from "../../../assets/images/radio_unactive.svg";

import "./check_form_widget.css";

import React from "react";

interface Props {
    isSelected: boolean;
    label: string;
    onChanged: (isSelected: boolean) => void;
}

export default class CheckFormWidget extends React.Component<Props> {
    render() {
        return <div id="CheckFormContainer" onClick={() => this.props.onChanged(!this.props.isSelected)}>
            <button>
                <img src={this.props.isSelected ? radio_active : radio_unactive} alt="" />
            </button>
            <p style={this.props.isSelected ? {
                fontWeight: 600
            } : {
                color: "#9BA0AD"
            }}>{this.props.label}</p>
        </div>;
    }
}