import header_logo from "../../../assets/images/uena_form_header.svg";

import { RefObject } from "react";
import Widget from "../../../commons/base/widget";
import UenaWebsiteState, { UenaWebsiteActiveMenu } from "../../view_models/uena_website_state";
import UenaWebsiteViewModel from "../../view_models/uena_website_view_model";
import UenaWebsiteScreen from "../../uena_website_screen";

interface Props {
    homeRef: RefObject<HTMLDivElement>;
    ourMissionRef: RefObject<HTMLDivElement>;
    ourMenuRef: RefObject<HTMLDivElement>;
    ourQualityRef: RefObject<HTMLDivElement>;
    howToOrderRef: RefObject<HTMLDivElement>;
    scrollToRef: (ref: RefObject<HTMLDivElement>) => void;
}

export default class UenaWebsiteDesktopHeaderWidget extends Widget<Props, UenaWebsiteState, UenaWebsiteViewModel, UenaWebsiteScreen> {
    homeRef: RefObject<HTMLDivElement>;
    ourMissionRef: RefObject<HTMLDivElement>;
    ourMenuRef: RefObject<HTMLDivElement>;
    ourQualityRef: RefObject<HTMLDivElement>;
    howToOrderRef: RefObject<HTMLDivElement>;

    get scrollToRef(): (ref: RefObject<HTMLDivElement>) => void {
        return this.props.scrollToRef;
    }

    constructor(props: Props & {vc: UenaWebsiteScreen}) {
        super(props);

        this.homeRef = props.homeRef;
        this.ourMissionRef = props.ourMissionRef;
        this.ourMenuRef = props.ourMenuRef;
        this.ourQualityRef = props.ourQualityRef;
        this.howToOrderRef = props.howToOrderRef;
    }

    render() {
        return <div id="HomeHeaderDesktopContainer">
            <img src={header_logo} alt="" />
            <div />
            <button 
                onClick={() => this.scrollToRef(this.homeRef)}
                className={this.vmState.activeMenu === UenaWebsiteActiveMenu.home ? "HomeMenuActive" : ""}
            >
                HOME
            </button>
            <button
                onClick={() => this.scrollToRef(this.ourMissionRef)}
                className={this.vmState.activeMenu === UenaWebsiteActiveMenu.ourMission ? "HomeMenuActive" : ""}
            >
                OUR MISSION
            </button>
            <button
                onClick={() => this.scrollToRef(this.ourMenuRef)}
                className={this.vmState.activeMenu === UenaWebsiteActiveMenu.ourMenu ? "HomeMenuActive" : ""}
            >
                OUR MENU
            </button>
            <button
                onClick={() => this.scrollToRef(this.ourQualityRef)}
                className={this.vmState.activeMenu === UenaWebsiteActiveMenu.ourQuality ? "HomeMenuActive" : ""}
            >
                OUR QUALITY
            </button>
            <button
                onClick={() => this.scrollToRef(this.howToOrderRef)}
                className={this.vmState.activeMenu === UenaWebsiteActiveMenu.howToOrder ? "HomeMenuActive" : ""}
            >
                HOW TO ORDER
            </button>
            <div style={{opacity: (Math.min(this.vmState.scrollPosition, 120) / 120) * 0.8}}/>
        </div>;
    }
}