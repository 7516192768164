import "./radio_form_widget.css";

import React from "react";
import LabelAndIdModel from "../../models/LabelIdModel";

interface Props {
    items: LabelAndIdModel[];
    selectedId?: string;
    onSelectItem: (id: string, index: number) => void;
}

export default class RadioFormWidget extends React.Component<Props> {
    render() {
        return <div id="RadioFormContainer">
            {
                this.props.items.map(
                    (item, index) => 
                        <button 
                            key={item.id}
                            className={item.id === this.props.selectedId ? "RadioFormActiveButton" : "RadioButtonDisableButton"} 
                            onClick={() => this.props.onSelectItem(item.id, index)
                        }>
                            {item.label}
                        </button>)
            }
        </div>;
    }
}