import "./loading_indicator_widget.css";

import React from "react";

export default class LoadingWidget extends React.Component {
    render() {
        return (
            <div className="LoadingWidgetContainer">
                <div />
                <div />
                <div />
            </div>
        );
    }
}