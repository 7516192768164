import React from "react";
import ViewController from "./view_controller";
import ViewModel from "./view_model";

export default class Widget<Props, State, VM extends ViewModel<State>, VC extends ViewController<any, State, VM>> extends React.Component<Props & { vc: VC }> {
    get viewModel() {
        return this.props.vc.viewModel;
    }

    get vmState() {
        return this.props.vc.vmState;
    }
}