import { ShowErrorViewModel, ShowSnackbarModel } from "../../../commons/scaffold/scaffold";
import ReferralDisplayResponse from "../models/referral_display_response";

export default class ReferralState {
    isLoading: boolean = true;
    response?: ReferralDisplayResponse;
    activeTabIndex: number = 0;
    errorViewModel?: ShowErrorViewModel;
    infoSnackbarModel?: ShowSnackbarModel;

    get invitedUsersCounting() {
        return this.response?.invitedUsers.length ?? 0;
    }
}