import how_to_order_1 from "../../../assets/images/how_to_order_1.png";
import how_to_order_2 from "../../../assets/images/how_to_order_2.png";
import how_to_order_3 from "../../../assets/images/how_to_order_3.png";
import uena_vertical_logo from "../../../assets/images/uena_vertical_logo.svg";
import ic_ig from "../../../assets/images/ic_ig.svg";
import ic_fb from "../../../assets/images/ic_fb.svg";
import ic_tiktok from "../../../assets/images/ic_tiktok.svg";

import download_ios from "../../../assets/images/download-ios.png";
import download_android from "../../../assets/images/download-android.png";

import Widget from "../../../commons/base/widget";
import UenaWebsiteState from "../../view_models/uena_website_state";
import UenaWebsiteViewModel from "../../view_models/uena_website_view_model";
import UenaWebsiteScreen from "../../uena_website_screen";
import { RefObject } from "react";

interface Props {
    howToOrderRef: RefObject<HTMLDivElement>;
}

export default class UenaWebsiteMobileHowToOrderWidget extends Widget<Props, UenaWebsiteState, UenaWebsiteViewModel, UenaWebsiteScreen> {
    render() {
        return <div ref={this.props.howToOrderRef}>
            <div id="HomeHowToOrderMobileContainer">
                <h2>How to <strong>Order?</strong></h2>
                <div>
                    <a href="https://api.whatsapp.com/send/?phone=62811303010&text=Halo%20saya%20mau%20pesan&app_absent=0">
                        <img src={how_to_order_1} alt="" />
                        <p>Quick Order Via WhatsApp</p>
                    </a>
                    <a href="https://linktr.ee/UENAFood">
                        <img src={how_to_order_2} alt="" />
                        <p>GoFood, Grabfood, and ShopeeFood</p>
                    </a>
                    <a href="/download">
                        <img src={how_to_order_3} alt="" />
                        <p>UENA Mobile App</p>
                    </a>
                </div>
            </div>
            <div id="HomeFooterMobileContainer">
                <div>
                    <div>
                        <img src={uena_vertical_logo} alt="" />
                    </div>
                    <div />
                    <div>
                        <div>
                            <div id="HomeFooterFollowUsMobileContainer">
                                <p>Follow Us</p>
                                <div>
                                    <a href="https://www.instagram.com/uenafood/"><img src={ic_ig} alt=""/></a>
                                    <a href="https://www.facebook.com/uenafood"><img src={ic_fb} alt=""/></a>
                                    <a href="https://tiktok.com/@uenafood"><img src={ic_tiktok} alt=""/></a>
                                </div>
                            </div>
                            <div id="HomeFooterDownloadMobileContainer">
                                <p>Download Now</p>
                                <div>
                                    <a href="https://apps.apple.com/id/app/uena-pujasera-online/id1638605441"><img src={download_ios} alt="" /></a>
                                    <a href="https://play.google.com/store/apps/details?id=com.uena.app"><img src={download_android} alt="" /></a>
                                </div>
                            </div>
                        </div>
                        <p style={{
                            fontSize: 12,
                            textAlign: "end",
                        }}>UENA ©2023. All Rights Reserved.</p>
                    </div>
                </div>
            </div>
        </div>;
    }
}