import React from "react";
import SizedDiv from "../../commons/ui/sized_div";
import BooleanUtils from "../../commons/utils/boolean_utils";
import OrderFormProductCollectionModel from "../models/order_form_product_collection_model";
import OrderFormProductModel from "../models/order_form_product_model";
import CreateOrderMenuCellWidget from "./create_order_menu_cell_widget";

interface Props {
    productCollections: OrderFormProductCollectionModel[];
    selectedFilter: boolean[];
    productAndQuantity: Map<string, {
        quantity: number;
        model: OrderFormProductModel;
    }>;
    refreshId: number;
    onCategoryTickerSelected: (index: number, isSelected: boolean) => void;
    onProductQuantityOrNotesChanged: (id: string, quantity: number, model: OrderFormProductModel, isVoucherRequired: boolean) => void;
    screenWidth: number;
}

export default class CreateOrderMenuSectionWidget extends React.Component<Props> {
    render() {
        return  <div id="CreateOrderMenuSectionContainer">
            <p className="FormLabel" style={{fontSize:24, fontWeight:700}}>Pesanan</p>
            <div id="CreateOrderMenuCategoryTickerContainer">
                {
                    this.props.productCollections.map((collectionModel, index) => 
                        <button 
                            key={index}
                            className={this.props.selectedFilter[index] ? "Active" : "Unactive"}
                            onClick={() => this.props.onCategoryTickerSelected(
                                index, 
                                !this.props.selectedFilter[index]
                            )}
                        >{collectionModel.kind}</button>
                    )
                }
            </div>
            {
                this.props.productCollections.filter(
                    (_, index) => this.props.selectedFilter[index] || BooleanUtils.getIsAllFalse(this.props.selectedFilter)
                ).map((collectionModel, index) => 
                    <div key={index}>
                        <p className="FormLabel">{collectionModel.kind}</p>
                        <SizedDiv height={12} />
                        <div id="CreateOrderMenuWrapper">
                            {
                                collectionModel.items.map((productModel, index) =>
                                    <CreateOrderMenuCellWidget 
                                        key={index}
                                        refreshId={this.props.refreshId}
                                        model={productModel} 
                                        productAndQuantity={this.props.productAndQuantity}
                                        onProductQuantityOrNotesChanged={this.props.onProductQuantityOrNotesChanged}
                                        contentWidth={this.props.screenWidth - 48}
                                    />
                                )
                            }
                        </div>
                    </div>
                )
            }
        </div>;
    }
}