import "./website.css";
import "swiper/css";
import "swiper/css/navigation";

import order_now from "../assets/images/order_now.png";
import order_now_circle from "../assets/images/order_now_circle.svg";

import ViewController from "../commons/base/view_controller";
import UenaWebsiteState from "./view_models/uena_website_state";
import UenaWebsiteViewModel from "./view_models/uena_website_view_model";
import { RefObject, createRef } from "react";
import UenaWebsiteDesktopHeaderWidget from "./widgets/desktop/uena_website_desktop_header_widget";
import UenaWebsiteMobileHeaderWidget from "./widgets/mobile/uena_website_mobile_header_widget";
import UenaWebsiteDesktopSummaryWidget from "./widgets/desktop/uena_website_desktop_summary_widget";
import UenaWebsiteDesktopMissionWidget from "./widgets/desktop/uena_website_desktop_mission_widget";
import UenaWebsiteDesktopMenuWidget from "./widgets/desktop/uena_website_desktop_menu_widget";
import UenaWebsiteDesktopQualityWidget from "./widgets/desktop/uena_website_desktop_quality_widget";
import UenaWebsiteMobileSummaryWidget from "./widgets/mobile/uena_website_mobile_summary_widget";
import UenaWebsiteMobileMissionWidget from "./widgets/mobile/uena_website_mobile_mission_widget";
import UenaWebsiteMobileMenuWidget from "./widgets/mobile/uena_website_mobile_menu_widget";
import UenaWebsiteMobileQualityWidget from "./widgets/mobile/uena_website_mobile_quality_widget";
import UenaWebsiteDesktopWhyUenaWidget from "./widgets/desktop/uena_website_desktop_why_uena_widget";
import UenaWebsiteMobileWhyUenaWidget from "./widgets/mobile/uena_website_mobile_why_uena_widget";
import UenaWebsiteMobileHowToOrderWidget from "./widgets/mobile/uena_website_mobile_how_to_order_widget";
import UenaWebsiteDesktopHowToOrderWidget from "./widgets/desktop/uena_website_desktop_how_to_order_widget";

export default class UenaWebsiteScreen extends ViewController<{}, UenaWebsiteState, UenaWebsiteViewModel> {
    homeRef: RefObject<HTMLDivElement> = createRef();
    ourMissionRef: RefObject<HTMLDivElement> = createRef();
    ourMenuRef: RefObject<HTMLDivElement> = createRef();
    ourQualityRef: RefObject<HTMLDivElement> = createRef();
    howToOrderRef: RefObject<HTMLDivElement> = createRef();

    get isDesktopViewSize() : boolean {
        return this.state.width > 950 && this.state.height > 650;
    }
    
    constructor(props: {}) {
        super(props, new UenaWebsiteViewModel());
        window.addEventListener('scroll', () => this.viewModel.updateScrollPosition(this.state.height, this.isDesktopViewSize));
    }

    onScreenResizing() {
        super.onScreenResizing();
        this.viewModel.updateScrollPosition(this.state.height, this.isDesktopViewSize);
    }

    render() {
        return <div id="HomeMainContainer">
            {
                this.isDesktopViewSize
                ? <UenaWebsiteDesktopHeaderWidget 
                    vc={this}
                    homeRef={this.homeRef}
                    ourMissionRef={this.ourMissionRef}
                    ourMenuRef={this.ourMenuRef}
                    ourQualityRef={this.ourQualityRef}
                    howToOrderRef={this.howToOrderRef}
                    scrollToRef={this.scrollToRef}
                />
                : <UenaWebsiteMobileHeaderWidget 
                    vc={this}
                    homeRef={this.homeRef}
                    ourMissionRef={this.ourMissionRef}
                    ourMenuRef={this.ourMenuRef}
                    ourQualityRef={this.ourQualityRef}
                    howToOrderRef={this.howToOrderRef}
                    scrollToRef={this.scrollToRef}
                />
            }
            <a href="https://api.whatsapp.com/send/?phone=62811303010&text=Halo%20saya%20mau%20pesan&app_absent=0" style={{bottom: this.vmState.orderNowBottomInset}}>
                <img src={this.isDesktopViewSize ? order_now : order_now_circle} alt="" />
            </a>
            {
                this.isDesktopViewSize 
                ? <>
                    <UenaWebsiteDesktopSummaryWidget 
                        vc={this}
                        homeRef={this.homeRef}
                        ourMenuRef={this.ourMenuRef}
                        scrollToRef={this.scrollToRef}
                    />
                    <UenaWebsiteDesktopMissionWidget 
                        vc={this}
                        ourMissionRef={this.ourMissionRef}
                    />
                    <UenaWebsiteDesktopMenuWidget
                        vc={this}
                        ourMenuRef={this.ourMenuRef}
                    />
                    <UenaWebsiteDesktopQualityWidget 
                        vc={this}
                        ourQualityRef={this.ourQualityRef}
                    />
                    <UenaWebsiteDesktopWhyUenaWidget
                        vc={this}
                    />
                    <UenaWebsiteDesktopHowToOrderWidget
                        vc={this}
                        howToOrderRef={this.howToOrderRef}
                    />
                </> 
                : <>
                    <UenaWebsiteMobileSummaryWidget
                        vc={this}
                        homeRef={this.homeRef}
                        ourMenuRef={this.ourMenuRef}
                        scrollToRef={this.scrollToRef}
                    />
                    <UenaWebsiteMobileMissionWidget
                        vc={this}
                        ourMissionRef={this.ourMissionRef}
                    />
                    <UenaWebsiteMobileMenuWidget
                        vc={this}
                        ourMenuRef={this.ourMenuRef}
                        screenWidth={this.state.width}
                    />
                    <UenaWebsiteMobileQualityWidget 
                        vc={this}
                        ourQualityRef={this.ourQualityRef}
                    />
                    <UenaWebsiteMobileWhyUenaWidget
                        vc={this}
                    />
                    <UenaWebsiteMobileHowToOrderWidget
                        vc={this}
                        howToOrderRef={this.howToOrderRef}
                    />
                </>
            }
        </div>;
    }

    private scrollToRef(ref: RefObject<HTMLDivElement>) {
        ref.current?.scrollIntoView({
            behavior: "smooth",
            block: "start"
        });
    }
}