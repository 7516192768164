import "./loading_state_view.css";

import React from "react";
import LoadingWidget from "./loading_indicator_widget";
import UenaLogoLoadingIndicatorWidget from "./uena_logo_loading_indicator_widget";

export type LoadingBackgroundType = "solid" | "semi_transparent";
export type LoadingType = "uena_logo" | "three_dots";

interface Props {
    backgroundType?: LoadingBackgroundType;
    loadingType?: LoadingType;
    backgroundColor?: string;
}

export default class LoadingStateView extends React.Component<Props> {
    render() {
        const backgroundColor = this.props.backgroundType === "semi_transparent" ? `${this.props.backgroundColor ?? "#000000"}77` : `${this.props.backgroundColor ?? "#000000"}`;
        return(
            <div id="LoadingViewBackground" style={{
                backgroundColor: backgroundColor
            }}>
                {
                    this.props.loadingType === "uena_logo" ? 
                    <UenaLogoLoadingIndicatorWidget /> :
                    <LoadingWidget />
                }
            </div>
        );
    }
}