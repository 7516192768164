import { ReactNode } from "react";

import logo from "../assets/images/franchise/logo.svg";
import background from "../assets/images/franchise/background.svg";
import foreground from "../assets/images/franchise/foreground.svg";
import whatsapp from "../assets/images/franchise/whatsapp.svg";
import instagram from "../assets/images/franchise/instagram.svg";
import whatsappPurple from "../assets/images/franchise/whatsapp-purple.svg";
import ViewController from "../commons/base/view_controller";
import { FranchiseLandingState, FranchiseLandingViewModel } from "./view_models/franchise_landing_view_model";

import "./franchise_landing.css";

import mb_f1 from "../assets/images/franchise/foreground-mb1.svg";
import mb_f2 from "../assets/images/franchise/foreground-mb2.svg";
import instagram_white from "../assets/images/franchise/instagram-white.svg";

export default class FranchiseLandingScreen extends ViewController<{}, FranchiseLandingState, FranchiseLandingViewModel> {
    constructor(props: {}) {
        super(props, new FranchiseLandingViewModel());
    }

    get isDesktopViewSize() : boolean {
        return this.state.width > 600 && this.state.height > 500;
    }

    render(): ReactNode {
        if (this.isDesktopViewSize) {
            return <div id="FranchiseLandingContainer">
                <img src={background} alt="background" />
                <img src={foreground} alt="foreground" />
                <div id="FranchiseLandingAppBar">
                    <a href="/home"><img src={logo} alt="logo" /></a>
                    <div />
                    <a href="https://wa.me/62811303044" >
                        <img src={whatsapp} alt="whatsapp" />
                        <p>Hubungi Kami</p>
                    </a>
                </div>
                <div id="FranchiseInfoContainer">
                    <p>Info Kemitraan</p>
                    <a href="https://wa.me/62811303044"><img src={whatsappPurple} alt="wa"/>Hubungi Kami</a>
                </div>
                <a id="FranchiseIGContainer" href="https://www.instagram.com/uenafood"><img src={instagram} alt="ig" />uenafood</a>
            </div>;
        }

        return <div id="FranchiseLandingMobileContainer">
            <img src={mb_f1} alt="" />
            <img src={mb_f2} alt="" />
            <a href="/home"><img src={logo} alt="logo" /></a>
            <div>
                <p>Info Kemitraan</p>
                <a href="https://wa.me/62811303044"><img src={whatsappPurple} alt="wa"/>Hubungi Kami</a>
                <a href="https://www.instagram.com/uenafood"><img src={instagram_white} alt="ig" />uenafood</a>
            </div>
        </div>
    }
}