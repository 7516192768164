import "./uena_logo_loading_indicator_widget.css";

import top_logo from "../../../assets/images/top_logo.svg";
import u from "../../../assets/images/u.svg";
import e from "../../../assets/images/e.svg";
import n from "../../../assets/images/n.svg";
import a from "../../../assets/images/a.svg";

import React from "react";

export default class UenaLogoLoadingIndicatorWidget extends React.Component {
    render() {
        return (
            <div className="UenaLogoLoadingWidgetContainer">
                <img src={top_logo} alt="" style={{
                    height: 36,
                    width: 36
                }}/>
                <div>
                    <img src={u} alt=""/>
                    <img src={e} alt=""/>
                    <img src={n} alt=""/>
                    <img src={a} alt=""/>
                </div>
                <p>TEMAN MAKAN SETIAP HARI</p>
            </div>
        );
    }
}