import "./mobile_qris.css";

import { useEffect, useRef } from "react";
import ViewController from "../../commons/base/view_controller";
import Scaffold from "../../commons/scaffold/scaffold";
import MobileQrisState from "./view_models/mobile_qris_state";
import MobileQrisViewModel from "./view_models/mobile_qris_view_model";
import QRCode from "qrcode";
import StringUtils from "../../commons/utils/string_utils";
import FormButton from "../../commons/ui/form_button";

export default class MobileQrisScreen extends ViewController<{}, MobileQrisState, MobileQrisViewModel> {
    constructor(props: {}) {
        super(props, new MobileQrisViewModel());
    }

    render() {
        const minuteDigit = Math.round(this.vmState.timeDifferenceInSecond / 60).toLocaleString('en-US', { minimumIntegerDigits: 2, useGrouping: false});
        const secondDigit = Math.round(this.vmState.timeDifferenceInSecond % 60).toLocaleString('en-US', { minimumIntegerDigits: 2, useGrouping: false});

        return <Scaffold
            isShowLoading={this.vmState.isLoading}
            showTopAppBar={false}
            loadingType="uena_logo"
            loadingBackgroundColor="#6B2C8F"
            showErrorView={this.vmState.errorViewModel}
            showSnackbar={this.vmState.errorSnackbar}
        >
            {
                this.vmState.timeDifferenceInSecond > 0 ?
                <div id="MobileQrisContainer">
                    <p>Pembayaran QRIS</p>
                    <p>Total Pembayaran</p>
                    <p>{StringUtils.convertToCurrency(this.vmState.response?.amount)}</p>
                    <p>Jatuh tempo dalam {`${minuteDigit}:${secondDigit}`}</p>
                    {
                        this.vmState.response?.qrString &&
                        <QRCodePreviewer qrString={this.vmState.response.qrString} />
                    }
                    <div id="MobileQrisInstructionContainer">
                        <div>!</div>
                        <p>Silakan screenshoot QRIS di atas dan bayar orderan kamu lewat dompet digital atau bank pilihan kamu.</p>
                    </div>
                    <div id="MobileQrisBottomSection">
                        <FormButton 
                            isLoading={this.vmState.isCheckingQrisStatus}
                            title="Saya Sudah Membayar" 
                            action={() => {
                                this.viewModel.handleCheckStatusIsPaid();
                            }}
                            backgroundColor="#6B2C8F"
                            textColor="white"
                        />
                    </div>
                </div> : 
                <div id="MobileQrisExpiredContainer">
                    <div>!</div>
                    <p>QRIS sudah tidak dapat digunakan karena sudah melewati masa tenggang waktu penggunaan QRIS. Silahan melakukan transaksi baru lagi.</p>
                </div>
            }
        </Scaffold>;
    }
}

function QRCodePreviewer(props: { qrString: string }) {
    const canvasRef = useRef();

    useEffect(() => {
        QRCode.toCanvas(
            canvasRef.current,
            props.qrString,
            (error) => error && console.log(error)
        )
    });

    return <div style={{
        display: "flex",
        justifyContent: "center"
    }}>
        <canvas ref={canvasRef as any}/>
    </div>;
}