import React, { ReactNode } from "react";
import FormButton from "../../../commons/ui/form_button";
import { SoForm } from "../models/SoFormModels";

export default class TableSOForms extends React.Component<{
    forms: SoForm[],
    location?: number,
    onBtnViewItemsClicked: (formId: number) => void
}> {
    selectedRow: number = -1;

    TableBody() {
        const forms = this.props.forms ?? [];
        const locId = this.props.location ?? -1;

        if (forms.length > 0) {
            // show data in SO form table only for the selected location
            return forms.filter((soForm) => soForm.locationId === locId)
            .map((form, index) => {
                const rowId = index + 1;
                return (
                    <tr 
                    key={rowId} 
                    className={this.selectedRow === rowId ? "FormSelected" : ""}>
                        <td>{rowId}</td>
                        <td>{form.id}</td>
                        <td>{form.respondentName}</td>
                        <td>{form.locationName}</td>
                        <td>{form.formDate}</td>
                        <td>
                            <FormButton
                                action={() => {
                                    this.selectedRow = rowId;
                                    this.props.onBtnViewItemsClicked(form.id);
                                }}
                                title="View"
                                backgroundColor="#58BAD7"
                                textColor="#FFF"
                            />
                        </td>
                    </tr>
                )
            });
        } else {
            return (
                <tr key={1}>
                    <td colSpan={7}>
                        <span>Data Tidak Tersedia</span>
                    </td>
                </tr>
            ) 
        }
    }

    render(): ReactNode {
        return (
            <table className="SoTable">
                <thead>
                    <tr>
                        <th>No.</th>
                        <th>Form Id</th>
                        <th>Respondent Name</th>
                        <th>Location</th>
                        <th>Created Date</th>
                        <th>View Items</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        this.TableBody()
                    }
                </tbody>
            </table>
        );
    }
}
