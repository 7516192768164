import landscape_home_foreground from "../../../assets/images/landscape_home_foreground.png";
import daily_img from "../../../assets/images/home_daily_img.png";
import free_deliv from "../../../assets/images/free_deliv.webp";
import low_price from "../../../assets/images/low_price.webp";
import mobile_display_ios from "../../../assets/images/mobile_display_ios.webp";

import Widget from "../../../commons/base/widget";
import UenaWebsiteState from "../../view_models/uena_website_state";
import UenaWebsiteViewModel from "../../view_models/uena_website_view_model";
import UenaWebsiteScreen from "../../uena_website_screen";
import { RefObject } from "react";

interface Props {
    homeRef: RefObject<HTMLDivElement>;
    ourMenuRef: RefObject<HTMLDivElement>;
    scrollToRef: (ref: RefObject<HTMLDivElement>) => void;
}

export default class UenaWebsiteDesktopSummaryWidget extends Widget<Props, UenaWebsiteState, UenaWebsiteViewModel, UenaWebsiteScreen> {
    render() {
        return <div ref={this.props.homeRef} style={{background:`url(${landscape_home_foreground}),#fc3`, backgroundBlendMode: "multiply"}}>
            <div id="HomeDesktopSummaryContainer">
                <div>
                    <h1>Tasty Quality Food</h1>
                    <img src={daily_img} alt=""/>
                    <div>
                        <div><p>Budget-Friendly Food</p> <img src={low_price} alt="" /></div>
                        <div><p>Low Cost Delivery</p> <img src={free_deliv} alt="" /></div>
                    </div>
                    <button onClick={() => this.props.scrollToRef(this.props.ourMenuRef)}>
                        Wow Really?
                    </button>
                </div>
                <div />
                <div>
                    <img src={mobile_display_ios} alt="" />
                </div>
            </div>
        </div>;
    }
}