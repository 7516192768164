import React from "react";

interface Props {
    label: string;
    isActive: boolean;
    onTapped: () => void;
}

export default class ReferralTabButtonWidget extends React.Component<Props> {
    render() {
        return <button 
            className={`ReferralTabButton ${this.props.isActive ? "Active" : "Unactive"}`}
            onClick={this.props.onTapped}
        >
            <p>{this.props.label}</p>
            <div />
        </button>;
    }
}