import pro_check from "../../../assets/images/pro_check.svg";

import Widget from "../../../commons/base/widget";
import UenaWebsiteState from "../../view_models/uena_website_state";
import UenaWebsiteViewModel from "../../view_models/uena_website_view_model";
import UenaWebsiteScreen from "../../uena_website_screen";

export default class UenaWebsiteMobileWhyUenaWidget extends Widget<{}, UenaWebsiteState, UenaWebsiteViewModel, UenaWebsiteScreen> {
    render() {
        return <div>
            <div id="HomeWhyUenaFoodcourtMobileContainer">
                <h2>Buy Food from the <strong>Foodcourt</strong>, not the Driver</h2>
                <div style={{
                    flexDirection: "column",
                    rowGap: 24
                }}>
                    <div>
                        <h2>Foodcourt</h2>
                        <div id="HomeWhyFoodcourtReasonMobileCell">
                            <h2>Satisfaction Guarantee<img src={pro_check} alt=""/></h2>
                            <p>Contact us directly if you are not satisfied, and we will do our best to correct it</p>
                        </div>
                        <div id="HomeWhyFoodcourtReasonMobileCell">
                            <h2>Menu Availability<img src={pro_check} alt=""/></h2>
                            <p>Availability of the menu is updated and accurate 100%</p>
                        </div>
                        <div id="HomeWhyFoodcourtReasonMobileCell">
                            <h2>Consistent Experience<img src={pro_check} alt=""/></h2>
                            <p>We remember your orders and how you like it</p>
                        </div>
                    </div>
                    <div>
                        <h2>Driver</h2>
                        <div id="HomeWhyNotDriverReasonMobileCell">
                            <h2>Cannot Guarantee Anything</h2>
                            <p>Contact Customer Service if you are not satisfied, and they will get back to you in 2x24 hours</p>
                        </div>
                        <div id="HomeWhyNotDriverReasonMobileCell">
                            <h2>Menu Availability</h2>
                            <p>Do not know the availability until the driver come to the store</p>
                        </div>
                        <div id="HomeWhyNotDriverReasonMobileCell">
                            <h2>Different Experience</h2>
                            <p>Different order, different driver, means different experience</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>;
    }
}