import { isAndroid, isIOS } from "react-device-detect";
import ViewModel from "../../commons/base/view_model";
import DownloadAppState from "./download_app_state";
import analytics from "../../commons/utils/analytics_utils";
import { logEvent } from "@firebase/analytics";
import { getLocationQueries } from "../../commons/utils/utils";

export default class DownloadAppViewModel extends ViewModel<DownloadAppState> {
    constructor() {
        super(new DownloadAppState());
        this.directToDownloadLink();
    }

    directToDownloadLink() {
        let trackEvent: string;

        if (isAndroid) {
            trackEvent = "go_to_play_store";
        } else if (isIOS) {
            trackEvent = "go_to_app_store";
        } else {
            trackEvent = "go_to_home_screen";
        }

        logEvent(analytics, trackEvent, {
            ...getLocationQueries()
        });

        setTimeout(() => {
            if (isAndroid) {
                window.location.href = "https://play.google.com/store/apps/details?id=com.uena.app";
            } else if (isIOS) {
                window.location.href = "https://apps.apple.com/id/app/uena-pujasera-online/id1638605441";
            } else {
                window.location.href = "https://uenafood.com";
            }
        }, 2500);
    }
}