import copy_purple from "../../../assets/images/copy-purple.svg";

import Widget from "../../../commons/base/widget";
import ReferralState from "../view_models/referral_state";
import ReferralViewModel from "../view_models/referral_view_model";
import ReferralScreen from "../referral_screen";
import { Skeleton } from "@mui/material";

interface Props {
    imageTopBackgroundHeight: number;
}

export default class ReferralCodeContainerWidget extends Widget<Props, ReferralState, ReferralViewModel, ReferralScreen> {
    render() {
        return <div id="ReferralCodeContainer" style={{
            marginTop: this.props.imageTopBackgroundHeight - 48,
        }}>
            <div>
                <div>
                    <p>Kode Referral</p>
                    {
                        this.vmState.response ?
                        <p>{this.vmState.response?.referralCode ?? ""}</p> :
                        <Skeleton variant="rounded" width="60%" height={38} />
                    }
                </div>
                <button onClick={() => {
                    this.viewModel.copyReferralCode();
                }}>
                    <img src={copy_purple} alt="" />
                </button>
            </div>
        </div>;
    }
}