import menu_background from "../../../assets/images/menu_background.png";
import why_cheap_1 from "../../../assets/images/why_cheap_1.svg";
import why_cheap_2 from "../../../assets/images/why_cheap_2.svg";
import why_cheap_3 from "../../../assets/images/why_cheap_3.svg";
import why_cheap_4 from "../../../assets/images/why_cheap_4.svg";

import Widget from "../../../commons/base/widget";
import UenaWebsiteState from "../../view_models/uena_website_state";
import UenaWebsiteViewModel from "../../view_models/uena_website_view_model";
import UenaWebsiteScreen from "../../uena_website_screen";
import { RefObject } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination } from "swiper";
import LIST_OF_MENU from "../../models/home_menu_list_model";
import StringUtils from "../../../commons/utils/string_utils";
import SizedDiv from "../../../commons/ui/sized_div";

interface Props {
    ourMenuRef: RefObject<HTMLDivElement>;
    screenWidth: number;
}

export default class UenaWebsiteMobileMenuWidget extends Widget<Props, UenaWebsiteState, UenaWebsiteViewModel, UenaWebsiteScreen> {
    render() {
        return <div ref={this.props.ourMenuRef} style={{padding: "80px 0px 52px"}}>
            <div 
                id="HomeMenuDesktopContainer"
                style={{
                    width: "100%",
                }}
            >
                <h2 style={{fontSize: 18}}>All of your favorite daily menus <strong>in one place</strong></h2>
                <Swiper
                    centeredSlides={true}
                    loop={true}
                    navigation={true}
                    slidesPerView={this.props.screenWidth < 900 ? 1.4 : 3.5}
                    spaceBetween={24}
                    modules={[Pagination, Navigation]}
                    style={{
                        width: "100%"
                    }}
                >
                    {
                        LIST_OF_MENU.map((model, index) => {
                            return <SwiperSlide key={index}>
                                <div id="HomeMenuCell" style={{backgroundImage: `url(${menu_background})`}}>
                                    <div>
                                        <img 
                                            src={model.imageUrl}
                                            alt=""
                                            style={{
                                                width: "100%"
                                            }}
                                        />
                                        {
                                            model.isNew === true &&
                                            <div>
                                                <p>NEW</p>
                                            </div>
                                        }
                                    </div>
                                    <h2>{model.name}</h2>
                                    <h2>{StringUtils.convertToCurrency(model.price)}</h2>
                                    <button
                                        onClick={() => {
                                            if (!model.isComingSoon) {
                                                window.location.href = `https://api.whatsapp.com/send/?phone=62811303010&text=Halo%20saya%20mau%20pesan%20menu%20${model.name.replace(" ", "%20")}&app_absent=0`;
                                            }
                                        }}
                                        style={{
                                            backgroundColor: model.isComingSoon ? "#9B89B9" : "#6B2B90",
                                            color: model.isComingSoon ? "#EDE2FF" : "#fc3",
                                        }}
                                    >ORDER NOW</button>
                                </div>
                            </SwiperSlide>;
                        })
                    }
                </Swiper>
                <SizedDiv height={52} />
                <div id="WhyCheapMobileContainer">
                    <div>
                        <h2 style={{fontSize: 18}}>How is it possible?</h2>
                    </div>
                    <div>
                        <div style={{width: "calc(50% - 8px)"}}>
                            <img src={why_cheap_1} alt="" />
                            <p>We buy in very large quantity from the first-hand supplier</p>
                        </div>
                        <div style={{width: "calc(50% - 8px)"}}>
                            <img src={why_cheap_2} alt="" />
                            <p>We are more focused on serving delivery and pick-up to minimize rental cost</p>
                        </div>
                        <div style={{width: "calc(50% - 8px)"}}>
                            <img src={why_cheap_3} alt="" />
                            <p>Our experience since 1997 to minimize all waste, loss, and shrinkage</p>
                        </div>
                        <div style={{width: "calc(50% - 8px)"}}>
                            <img src={why_cheap_4} alt="" />
                            <p>We implement technology and automation to increase productivity</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>;
    }
}