import indonesia_spent_for_food from "../../../assets/images/indonesia_spent_for_food.svg";
import neighbour_spent_comparison from "../../../assets/images/neighbour_spent_comparison.svg";
import uena_mission from "../../../assets/images/uena_mission.svg";
import section_two_background from "../../../assets/images/section_two_background.png";

import Widget from "../../../commons/base/widget";
import UenaWebsiteState from "../../view_models/uena_website_state";
import UenaWebsiteViewModel from "../../view_models/uena_website_view_model";
import UenaWebsiteScreen from "../../uena_website_screen";
import { RefObject } from "react";

interface Props {
    ourMissionRef: RefObject<HTMLDivElement>;
}

export default class UenaWebsiteDesktopMissionWidget extends Widget<Props, UenaWebsiteState, UenaWebsiteViewModel, UenaWebsiteScreen> {
    render() {
        return <div ref={this.props.ourMissionRef} style={{backgroundImage: `url(${section_two_background})`}}>
            <div id="HomeDesktopMissionContainer">
                <div>
                    <img src={indonesia_spent_for_food} alt="" />
                    <div />
                    <img src={neighbour_spent_comparison} alt="" />
                </div>
                <div />
                <img src={uena_mission} alt="" />
            </div>
        </div>;
    }
}